import style from "./cartProCartProduct.module.scss";
import { IProCart, IProduct } from "../../../../types/proCart.types";
import { useModalComponent } from "../../../../context/modalComponent.context";
import { useContext, useState } from "react";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { IShopOperation } from "../../../../types/shop.types";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  PhotoCamera,
} from "@mui/icons-material";
import translate from "../../../../translation";
import ProTakePictureModal from "../../../../components/modals/proTakePictureModal/ProTakePictureModal";
import ModalPortal from "../../../../components/modals/ModalPortal";
import ReadModal from "../../../../components/modals/ReadModal/ReadModal";
import PrintProductLabelModal from "../../../../components/modals/printProductLabelModal/PrintProductLabelModal";

interface IProductShipping extends IProduct {
  canceled: boolean;
}

interface IProCartShipping extends IProCart {
  productsList: IProductShipping[];
}

interface CartProCardProductProps {
  cart: IProCartShipping;
  index: number;
  isChecked: boolean;
  setIsChecked: () => void;
  fileList: File[];
  setFileList: (fileList: File[]) => void;
  product: IProductShipping;
}

export default function CartProCardProduct({
  cart,
  index,
  isChecked,
  setIsChecked,
  fileList,
  setFileList,
  product,
}: CartProCardProductProps) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const [showPrintModal, setShowPrintModal] = useState(false)

  return (
    <>
    <div
      className={`${style["product-item"]} ${
        product.canceled ? style["canceled"] : ""
      }`}
    >
      <div className={style["product-item-container"]}>
        <div className={style["product-info"]}>
          <div className={style["product-info-top"]}>
            <WorkshopId workshopId={product.workshopId || "-"} size={"small"} />
            <div className={style["product-info-group-comment"]}>
              <div className={style["product-info-brand"]}>
                {product.brand || "-"}
                {product.canceled && (
                  <span className={style["canceled-logo"]}>{"annulé"}</span>
                )}
              </div>
              <div className={style["product-info-group"]}>{`${translate(
                product.productGroup
              )} - ${translate(product.operationGroup)}`}</div>
              {product.comments && (
                <div className={style["product-info-comment"]}>
                  {`${product.comments}`}
                </div>
              )}
            </div>
          </div>
          <ul className={style["operation-list"]}>
            {product.operationsList.map(
              (operation: IShopOperation, index: number) => {
                if (!operation.comments || operation.comments === "") {
                  return <li key={index}>{operation.description}</li>;
                }
                return (
                  <li key={index}>
                    {operation.description}
                    {" // "}
                    <i>{operation.comments}</i>
                  </li>
                );
              }
            )}
          </ul>
        </div>
        <div className={style["action-section"]}>
          {fileList && !product.canceled && (
            <div
              className={`${style["action-button"]} ${style["picture"]}`}
              onClick={() => handleOpenExpressModal(product)}
            >
              <PhotoCamera />
              {fileList.length > 0 &&
                `${fileList.length} image${fileList.length > 1 ? "s" : ""}`}
            </div>
          )}
          <div className={style["action-button"]} onClick={handleCheckProduct}>
            {isChecked ? (
              <CheckBox className={style["checkbox-icon"]} />
            ) : (
              <CheckBoxOutlineBlank />
            )}
          </div>
        </div>
      </div>
      {index !== cart.productsList.length - 1 && (
        <hr className={style["separator"]} />
      )}
    </div>
    <ModalPortal isOpen={showPrintModal}>
    <ReadModal handleCloseModal={() => {setShowPrintModal(false)}}>
      <PrintProductLabelModal product={product as any} comments={product.operationsList.map(o => o.comments).join('')}/>
    </ReadModal>
  </ModalPortal>
  </>
  );

  function handleCheckProduct() {
    if (!isChecked) {
      setShowPrintModal(true);
    }
    setIsChecked();
  }

  function handleOpenExpressModal(product: IProductShipping) {
    setModalChildren(
      <ProTakePictureModal
        fileList={fileList}
        setFileList={setFileList}
        product={product}
      />
    );
    setModalIsOpen(true);
  }
}
