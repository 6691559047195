import { IOperation } from "../types/accounting.types";

export const SLUG_TO_ACRONYM = new Map<string, string | string[]>([
  ["b2c_patins_chaussure_femme", "Pa"],
  ["b2c_patins_chaussure_homme", "Pa"],
  ["b2c_talons_chaussure_femme", "Ta"],
  ["b2c_talons_chaussure_homme", "Ta"],
  ["b2c_fers_encastres_chaussure_homme", "Fe"],
  ["b2c_fers_encastres_chaussure_femme", "Fe"],
  ["b2c_collage_chaussure_homme", "Coll"],
  ["b2c_collage_chaussure_femme", "Coll"],
  ["b2c_glissoirs_chaussure_homme", "Gli"],
  ["b2c_glissoirs_bottines_chaussure_homme", "Gli"],
  ["b2c_glissoirs_chaussure_femme", "Gli"],
  ["b2c_glissoirs_bottines_chaussure_femme", "Gli"],
  ["b2c_glissoirs_bottes_chaussure_femme", "Gli"],
  ["b2c_glissoirs_sneakers", "Gli"],
  ["b2c_enveloppes_chaussure_femme", "Env"],
  ["b2c_demi_semelle_cuir_chaussure_homme", "DemiC"],
  ["b2c_demi_semelle_cuir_chaussure_femme", "DemiC"],
  ["b2c_demi_semelle_caoutchouc_chaussure_homme", "DemiCaout"],
  ["b2c_demi_semelle_caoutchouc_chaussure_femme", "DemiCaout"],

  ["b2c_ressemelage_cuir_chaussure_homme", "RC"],
  ["b2c_ressemelage_caoutchouc_chaussure_homme", "RCaout"],
  ["b2c_ressemelage_crêpe_chaussure_homme", "RCrêpe"],
  ["b2c_ressemelage_cuir_chaussure_femme", "RC"],
  ["b2c_ressemelage_caoutchouc_chaussure_femme", "RCaout"],
  ["b2c_ressemelage_crepe_chaussure_femme", "RCrêpe"],
  ["b2c_ressemelage_sneakers", "RC"],
  ["b2c_ressemelage_escalade", "R"],
  ["b2c_enrobage_et_ressemelage_escalade", "R+E"],
  ["ressemelage_birkenstock_chaussure_homme", "RC Birk"],
  ["ressemelage_docmartens_chaussure_homme", "RC Doc"],
  ["b2c_ressemelage_birkenstock_chaussure_femme", "RC Birk"],
  ["b2c_ressemelage_docmartens_chaussure_femme", "RC Doc"],

  ["b2c_soin_creme_cirage_chaussure_homme", "Soins"],
  ["b2c_soin_nettoyage_daim_chaussure_homme", "Soins"],
  ["b2c_soin_glacage_chaussure_homme", ["Soins", "Gla"]],
  ["b2c_soin_creme_cirage_chaussure_femme", "Soins"],
  ["b2c_soin_nettoyage_daim_chaussure_femme", "Soins"],
  ["b2c_soin_nettoyage_sneakers_sneakers", "Soins"],
  ["b2c_soin_nettoyage_sneakers_complexe_sneakers", "Soins"],
  ["b2c_soin_nettoyage_ext_sac_25cm_maroquinerie", "Soins"],
  ["b2c_soin_nettoyage_ext_sac_+25cm_maroquinerie", "Soins"],
  ["b2c_soin_nettoyage_veste__maroquinerie", "Soins"],
  ["soin_nettoyage_matieres_sensibles_homme", "Soins"],
  ["soin_nettoyage_matieres_sensibles_femme", "Soins"],
  ["b2c_soin_nettoyage_repigmentation_sneakers", "Soins"],

  ["b2c_teinture_chaussure_homme", "Teinture"],
  ["b2c_teinture_bottines_chaussure_homme", "Teinture"],
  ["b2c_teinture_chaussure_femme", "Teinture"],
  ["b2c_teinture_bottines_chaussure_femme", "Teinture"],
  ["b2c_teinture_sneakers", "Teinture"],
  ["b2c_teinture_bottines_sneakers", "Teinture"],
  ["b2c_soin_changement_teinture", "Teinture"],
  ["b2c_teinture_masse", "Teinture"],
  ["b2c_premiere_de_proprete_chaussure_homme", "1ère"],
  ["b2c_premiere_de_proprete_chaussure_femme", "1ère"],

  ["b2c_redresse_talons_chaussure_homme", "Re"],
  ["b2c_redresse_talons_chaussure_femme", "Re"],
  ["b2c_collage_sneakers", "Coll"],
  ["b2c_collage_complexe_sneakers", "Coll"],
  ["b2c_patins_redresse_bouts_chaussure_homme", ["Pa", "Re"]],
  ["b2c_patins_redresse_bouts_chaussure_femme", ["Pa", "Re"]],
  ["b2c_talons_redresse_talons_chaussure_homme", ["Ta", "Re"]],
  ["b2c_talons_redresse_talons_chaussure_femme", ["Ta", "Re"]],
  ["b2c_patins_fers_encastres_chaussure_homme", ["Pa", "Fe"]],
  ["b2c_patins_fers_encastres_chaussure_femme", ["Pa", "Fe"]],
  [
    "b2c_patins_redresse_bouts_fers_encastrés_chaussure_homme",
    ["Pa", "Re", "Fe"],
  ],
  ["b2c_collage_couture_semelle_chaussure_homme", ["Coll", "Cou"]],
  ["b2c_collage_couture_semelle_chaussure_femme", ["Coll", "Cou"]],
  ["b2c_collage_couture_semelle_sneakers", ["Coll", "Cou"]],

  // ["b2c_changement_de_l_estampe_de_talon_chaussure_homme", ""],
  // ["b2c_changement_de_l_estampe_de_talon_chaussure_femme", ""],
  // ["b2c_changement_du_bas_des_envelopes_de_talons_chaussure_femme", ""],
  // ["b2c_demi_semelle_regent_chaussure_homme", ""],
  // ["b2c_demi_semelle_regent_redresse_bouts_chaussure_homme", ""],
  // ["b2c_demi_semelle_regent_chaussure_femme", ""],
  // ["b2c_demi_semelle_regent_redresse_bouts_chaussure_femme", ""],


  // OPERATIONS PRO
["ressemelage_escalade", "R"],
["enrobage_et_ressemelage_escalade", "R+E"],
["patins_chaussure_homme", ["Pa"]],
["patins_fers_encastres_chaussure_homme", ["Pa", "Fe"]],
["patins_redresse_bouts_chaussure_homme", ["Pa", "Re"]],
["demi_semelle_regent_redresse_bouts_chaussure_homme", ["Re"]],
["fers_encastres_chaussure_homme", ["Fe"]],
["redresse_bouts_chaussure_homme", ["Re"]],
["talons_chaussure_homme", ["Ta"]],
["talons_redresse_talons_chaussure_homme", ["Ta", "Re"]],
["redresse_talons_chaussure_homme", ["Re"]],
["ressemelage_cuir_chaussure_homme", ["RC"]],
["demi_semelle_cuir_chaussure_homme", ["DemiC"]],
["ressemelage_caoutchouc_chaussure_homme", ["RCaout"]],
["demi_semelle_caoutchouc_chaussure_homme", ["DemiCaout"]],
["ressemelage_crêpe_chaussure_homme", ["RCrêpe"]],
["glissoirs_chaussure_homme", ["Gli"]],
["glissoirs_bottines_chaussure_homme", ["Gli"]],
["premiere_de_proprete_chaussure_homme", ["1ère"]],
["soin_creme_cirage_chaussure_homme", ["Soin"]],
["soin_nettoyage_daim_chaussure_homme", ["Soin"]],
["soin_glacage_chaussure_homme", ["Soin", "Gla"]],
["teinture_chaussure_homme", ["Teinture"]],
["teinture_bottines_chaussure_homme", ["Teinture"]],
["collage_chaussure_homme", ["Coll"]],
["collage_couture_semelle_chaussure_homme", ["Coll", "Cou"]],
["changement_de_la_fag_25cm_chaussure_homme", ["Fag"]],
["patins_chaussure_femme", ["Pa"]],
["patins_fers_encastres_chaussure_femme", ["Pa", "Fe"]],
["patins_redresse_bouts_chaussure_femme", ["Pa", "Re"]],
["fers_encastres_chaussure_femme", ["Fe"]],
["redresse_bouts_chaussure_femme", ["Re"]],
["talons_chaussure_femme", ["Ta"]],
["talons_redresse_talons_chaussure_femme", ["Ta", "Re"]],
["redresse_talons_chaussure_femme", ["Re"]],
["enveloppes_chaussure_femme", ["Env"]],
["ressemelage_cuir_chaussure_femme", ["RC"]],
["demi_semelle_cuir_chaussure_femme", ["DemiC"]],
["ressemelage_caoutchouc_chaussure_femme", ["RCaout"]],
["demi_semelle_caoutchouc_chaussure_femme", ["DemiCaout"]],
["ressemelage_crepe_chaussure_femme", ["RCrêpe"]],
["glissoirs_chaussure_femme", ["Gli"]],
["glissoirs_bottines_chaussure_femme", ["Gli"]],
["glissoirs_bottes_chaussure_femme", ["Gli"]],
["premiere_de_proprete_chaussure_femme", ["1ère"]],
["soin_creme_cirage_chaussure_femme", ["Soin"]],
["soin_nettoyage_daim_chaussure_femme", ["Soin"]],
["teinture_chaussure_femme", ["Teinture"]],
["teinture_bottines_chaussure_femme", ["Teinture"]],
["collage_chaussure_femme", ["Coll"]],
["collage_couture_semelle_chaussure_femme", ["Coll", "Cou"]],
["changement_de_la_fag_25cm_chaussure_femme", ["Fag"]],
["changement_de_la_fag_+25cm_<_x_<_35cm_chaussure_femme", ["Fag"]],
["ressemelage_sneakers", ["RC"]],
["reprise_couture_sneakers", ["Cou"]],
["collage_sneakers", ["Coll"]],
["collage_complexe_sneakers", ["Coll"]],
["collage_couture_semelle_sneakers", ["Coll", "Cou"]],
["soin_nettoyage_sneakers_sneakers", ["Soin"]],
["soin_nettoyage_sneakers_complexe_sneakers", ["Soin"]],
["teinture_sneakers", ["Teinture"]],
["teinture_bottines_sneakers", ["Teinture"]],
["changement_de_la_fag_25cm_sneakers", ["Fag"]],
["glissoirs_sneakers", ["Gli"]],
["soin_nettoyage_ext_sac_25cm_maroquinerie", ["Soin"]],
["soin_nettoyage_ext_sac_+25cm_maroquinerie", ["Soin"]],
["soin_nettoyage_veste__maroquinerie", ["Soin"]],
["changement_de_la_bandouilliere_maroquinerie", ["Bandou"]],
["changement_d_une_poignee_anse_maroquinerie", ["Poign"]],
["changement_des_deux_poignees_anses_maroquinerie", ["Poign x2"]],
["changement_de_la_doublure_du_sac_25cm_a_partir_de_maroquinerie", ["Doubl"]],
["changement_de_la_doublure_du_sac_+25_cm_a_partir_de_maroquinerie", ["Doubl"]],
["changement_d_un_jonc_maroquinerie", ["Jonc"]],
["changement_de_deux_joncs_maroquinerie", ["Jonc x2"]],
["changement_de_la_fag_25cm_maroquinerie", ["Fag"]],
["changement_de_la_fag_+25cm_<_x_<_35cm_maroquinerie", ["Fag"]],
["changement_du_curseur_maroquinerie", ["Curs"]],
["changement_du_fermoir_maroquinerie", ["Ferm"]],
["changement_d_une_enchappe_prix_a_l_unite_maroquinerie", ["Ench"]],
["changement_de_la_tirette_de_curseur_maroquinerie", ["Tir"]],
["changement_d_un_mousqueton_maroquinerie", ["Mous"]],
["ressemelage_escalade", ["R"]],
["enrobage_et_ressemelage_escalade", ["R+E"]],
["patins_redresse_bouts_fers_encastrés_chaussure_homme", ["Pa", "Re", "Fe"]],
["soin_changement_teinture", ["Teinture"]],
["teinture_masse", ["Teinture"]],
["changement_de_deux_enchapes_maroquinerie", ["Ench x2"]],
["changement_d_ring_unite", ["DRing"]],
["changement_d_ring_paire", ["DRing x2"]],
["changement_de_mousqueton_paire_maroquinerie", ["Mous x2"]],
["changement_de_patch_logo_maroquinerie", ["Logo"]],
["couture_chaine_tressée_maroquinerie", ["Chaine"]],
["soin_nettoyage_matieres_sensibles_femme", ["Soin"]],
["soin_nettoyage_matieres_sensibles_homme", ["Soin"]],
["soin_nettoyage_repigmentation_femme", ["Repig"]],
["ressemelage_birkenstock_chaussure_homme", ["RC Birk"]],
["ressemelage_docmartens_chaussure_homme", ["RC Doc"]]

]);

export const ACRONYM_LEVEL = new Map<string, number>([
  ["Pa", 0],
  ["Re", 0],
  ["Ta", 0],
  ["Fe", 0],
  ["R", 0],
  ["R+E", 0],
  ["Logo", 0],
  ["Mous", 0],
  ["Mous x2", 0],
  ["Ench", 0],
  ["Ench x2", 0],
  ["Tir", 0],

  ["Coll", 1],
  ["Gli", 1],
  ["Cou", 1],
  ["Env", 1],
  ["Chaine", 1],
  ["DRing", 1],
  ["DRing x2", 1],
  ["Jonc", 1],
  ["Jonc x2", 1],
  ["Tir", 1],
  ["Curs", 1],
  ["Fag", 0],

  ["DemiC", 2],
  ["DemiCaout", 2],
  ["RC", 2],
  ["RCaout", 2],
  ["RCrêpe", 2],
  ["RC Birk", 2],
  ["RC Doc", 2],
  ["Doubl", 2],
  ["Poign", 2],
  ["Poign x2", 2],
  ["Bandou", 2],

  ["1ère", 3],
  ["Teinture", 3],
  ["Soins", 3],
  ["Repig", 3],

]);

export const ACRONYM = [
  "R",
  "R+E",
  "Pa",
  "Re",
  "Ta",
  "Fe",
  "Coll",
  "Gli",
  "Cou",
  "Env",
  "DemiC",
  "DemiCaout",
  "RC",
  "RCaout",
  "RCrêpe",
  "RC Birk",
  "RC Doc",
  "1ère",
  "Teinture",
  "Soins",

  "Logo",
  "Repig",
  "Chaine",
  "Mous",
  "Mous x2",
  "DRing",
  "DRing x2",
  "Ench",
  "Ench x2",

  "Tir",
  "Curs",
  "Ferm",
  "Fag",

  "Jonc",
  "Jonc x2",
  "Doubl",
  "Poign",
  "Poign x2",
  "Bandou"
];
