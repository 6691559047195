import Image from "../../../../image/Image";
import WorkshopId from "../../../../workshopId/workshopId";
import style from "./modalDetailedProduct.module.scss";
import { ICommentObject, IProduct } from "../../../../../types/logistic.types";
import {
  dateDiffInDays,
  isValidJSONString,
  sortOperations,
} from "../../../../../utils/utils";
import translate from "../../../../../translation";
import { COLOR_LIST } from "../../../../../translation/color";
import { IOperation } from "../../../../../types/accounting.types";
import DEFAULT_PICTURE from "../../../../../assets/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg";
import { useImageViewer } from "../../../../../context/imageViewer.context";
import State from "../../../../State/State";
import { PRODUCT_STATE } from "../../../../../utils/logistic.init";
import { useQuery } from "react-query";
import { getProduct, updateProduct } from "../../../../../requests/product";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../../utils/toast.options";
import { toast } from "react-toastify";
import PictureLoadingSpinner from "../../../../loadingSpinner/pictureloadingSpinner";
import Button from "../../../../Button/Button";
import { useState } from "react";
import ModalPortal from "../../../ModalPortal";
import PrintProductLabelModal from "../../../printProductLabelModal/PrintProductLabelModal";
import ReadModal from "../../../ReadModal/ReadModal";
import PrintLabel from "../../../../PrintLabel/PrintLabel";
import PrintLabelButton from "../../../../PrintLabel/PrintLabel";

interface ModalProductProps {
  product: IProduct;
}

export default function ModalDetailedProduct({ product }: ModalProductProps) {
  const { setViewerIsOpen, setPictureList, setIndex } = useImageViewer();
  const colorCode = COLOR_LIST.find(
    (value: any) => product.color === value.name
  )?.color;
  const { data, isFetching, refetch } = useQuery(
    ["product", product],
    () => getProduct(product.id),
    { refetchOnWindowFocus: false }
  );
  const [printLabelModal, setPrintLabelModal] = useState<boolean>(false);
  const OTHER_ARTICLE: ICommentObject = parseComment();

  if (isFetching) {
    return (
      <div className={style["loading-container"]}>
        <PictureLoadingSpinner color="gold" />
      </div>
    );
  }

  return (
    <>
      <div className={style["modal-detailed-product"]}>
        {data.dueDate && (
          <div className={style["due-date"]}>
            {`Date prévu de livraison: ${new Date(
              data.dueDate
            ).toLocaleDateString("fr-FR")} (${dateDiffInDays(
              new Date(),
              new Date(data.dueDate)
            )} jours)`}
          </div>
        )}
        <div className={style["modal-detailed-product-top"]}>
          <div className={style["left"]}>
            <div className={style["left-top"]}>
              <WorkshopId workshopId={data.workshopId} size="large" />
              <State
                state={data.status}
                className={style["state"]}
                stateList={PRODUCT_STATE}
                handleSetState={handleChangeProductState}
              />
            </div>
            <div className={style["product-operation-group"]}>
              {translate(data.productGroup)}
              {" - "} {translate(data.operationGroup)}
            </div>
            <div className={style["product-info"]}>
              <span>Marque</span> {data.brand ?? "-"}
            </div>
            <div className={style["product-info-line"]}>
              <div className={style["product-info-line-item"]}>
                <span>Catégorie</span> {data.category ? data.category : "-"}
              </div>
              <div className={style["product-info-line-item"]}>
                <span>Modèle</span> {data.model ?? "-"}
              </div>
            </div>
            <div className={style["product-info-line"]}>
              <div className={style["product-info-line-item"]}>
                <span>Couleur</span>
                <div className={style["product-color"]}>
                  {colorCode ? (
                    <div
                      className={style["color"]}
                      style={{ backgroundColor: `${colorCode}` }}
                    ></div>
                  ) : (
                    <>
                      <div className={style["empty-color"]}></div>
                      <div className={style["diagonal-line"]}></div>
                    </>
                  )}
                  {data.color}
                </div>
              </div>
              <div className={style["product-info-line-item"]}>
                <span>Taille</span> {data.size ?? "-"}
              </div>
            </div>
            {OTHER_ARTICLE && (
              <div className={style["other-article"]}>
                {OTHER_ARTICLE.embauchoir &&
                  `Embauchoir: ${OTHER_ARTICLE.embauchoir}`}
                <span>
                  {OTHER_ARTICLE.pochon && `Pochon: ${OTHER_ARTICLE.pochon}`}
                </span>
                {OTHER_ARTICLE.other && `Autres: ${OTHER_ARTICLE.other}`}
              </div>
            )}
          </div>
          <div className={style["right"]}>
            {data.incomingPicturesList && (
              <div className={style["picture-container"]}>
                <Image
                  className={style["main-picture"]}
                  src={data.incomingPicturesList[0]}
                  index={0}
                  pictureList={data.incomingPicturesList.concat(
                    data.outgoingPicturesList || []
                  )}
                />
                {data.incomingPicturesList.length +
                  (product?.outgoingPicturesList?.length || 0) >
                  1 && (
                  <div
                    className={style["more-picture"]}
                    onClick={() => handlePictureClick(0)}
                  >
                    +
                    {data.incomingPicturesList.length +
                      (product?.outgoingPicturesList?.length || 0) -
                      1}
                  </div>
                )}
              </div>
            )}
            <PrintLabelButton product={product} />
          </div>
        </div>
        <div className={style["operation-list-container"]}>
          <span>
            Liste des opérations{" ("}
            {
              data.operationsList.filter((op: IOperation) => !op.canceled)
                .length
            }
            {")"}
          </span>
          <ul className={style["operation-list"]}>
            {data.operationsList
              .sort(sortOperations)
              .map((operation: IOperation, index: number) => {
                return (
                  <li
                    className={`${
                      operation.canceled && style["strikethrough"]
                    }`}
                    key={index}
                  >
                    {operation.description}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className={style["history-list"]}>
          <span>{"Historique du produit (dernière activité)"}</span>
          {data.historiesList &&
          data.historiesList[data.historiesList.length - 1] ? (
            <div className={style["history-item"]}>
              <span>
                {new Date(
                  data.historiesList[data.historiesList.length - 1].timeStamp
                ).toLocaleString()}
              </span>
              <span className={style["history-state"]}>
                {translate(
                  data.historiesList[data.historiesList.length - 1].state
                )}
              </span>
              <span className={style["history-comment"]}>
                {data.historiesList[data.historiesList.length - 1].comments ??
                  "-"}
              </span>
            </div>
          ) : (
            <div
              className={style["empty-history-message"]}
            >{`aucun historique`}</div>
          )}
        </div>
      </div>
      <ModalPortal isOpen={printLabelModal}>
        <ReadModal handleCloseModal={handleClosePrintLabelModal}>
          <PrintProductLabelModal
            product={product}
            comments={
              product?.dueDate
                ? "à faire avant le: " +
                  new Date(product.dueDate).toLocaleDateString("fr-FR")
                : ""
            }
          />
        </ReadModal>
      </ModalPortal>
    </>
  );

  function handleClosePrintLabelModal() {
    setPrintLabelModal(false);
  }

  function handleOpenPrintLabelModal() {
    setPrintLabelModal(!printLabelModal);
  }

  function parseComment() {
    if (data && data.comments) {
      if (!isValidJSONString(data.comments)) {
        return {};
      } else {
        return JSON.parse(data.comments);
      }
    }
  }

  function handlePictureClick(index: number) {
    if (data.incomingPicturesList) {
      setViewerIsOpen(true);
      setIndex(index);
      setPictureList(data.incomingPicturesList || DEFAULT_PICTURE);
    }
  }

  async function handleChangeProductState(newState: string) {
    try {
      await updateProduct({ ...product, status: newState });
      refetch();
      toast.success("Etat mis à jour avec succès.", TOAST_SUCCESS_OPTIONS);
    } catch (error) {
      toast.error("Un problème est survenu", TOAST_ERROR_OPTIONS);
    }
  }
}
